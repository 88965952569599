import { useState } from "react";
import { PinInput } from "react-input-pin-code"; // ES Module

const pinInputStyle = {
  marginLeft: "10px",
  textAlign: "center",
  width: "35px",
  height: "35px",
};

const VerifyMobileNumber = ({ mobileNumber, formData, values, setValues }) => {
  return (
    <div>
      <p className="font-md d-flex justify-content-center">
        SMS sent to{" "}
        <span className=" fw-600" style={{ marginLeft: "10px" }}>
          {" "}
          +{mobileNumber}
        </span>
      </p>
      <div
        className="d-flex justify-content-center"
        style={{ marginBottom: "20px" }}
      >
        <PinInput
          values={values}
          onChange={(value, index, values) => setValues(values)}
        />
      </div>
    </div>
  );
};

export default VerifyMobileNumber;
