
import './App.css';
import './components/OnBoarding'
import OnBoarding from './components/OnBoarding';
import InviteSteps from './components/InviteSteps';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className="main-wrapper">
      <div className="nav-header bg-white shadow-xs border-0">
        <div className="nav-top">
          <a href="/#">
            <img src="/assets/img/logo.png" alt="logo" />
          </a>
        </div>
      </div>
      <InviteSteps />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}

export default App;
