import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";

import "react-phone-input-2/lib/bootstrap.css";
const OnBoarding = ({
  formData,
  setFormData,
  uploadedImage,
  setUploadedImage,
}) => {
  const handleImageClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedImage(reader.result);
      };
      reader.readAsDataURL(file);
    };
    input.click();
  };

  const handleFormChange = (text) => (e) => {
    setFormData({ ...formData, [text]: e.target.value });
  };

  return (
    <div>
      <div className="row justify-content-center">
        <div className="col-lg-4 text-center btn-new-photo">
          <figure className="avatar ms-auto me-auto mb-0 mt-2 w100">
            <div className="relative">
              <img
                src={uploadedImage ? uploadedImage : "/assets/img/user.png"}
                alt=""
                id="imageid"
                className="shadow-sm rounded-3 w-100"
              />
              <p className="" onClick={handleImageClick}>
                Edit
              </p>
            </div>
          </figure>
          <h2 className="fw-700 font-sm text-grey-900 mt-3">
            {formData.firstName + " " + formData.lastName}
          </h2>
        </div>
      </div>

      <form action="#">
        <div className="row">
          <div className="col-lg-6 mb-3">
            <div className="form-group">
              <label className="mont-font fw-600 font-xsss">First Name*</label>
              <input
                type="text"
                className="form-control"
                value={formData.firstName}
                onChange={handleFormChange("firstName")}
              />
            </div>
          </div>

          <div className="col-lg-6 mb-3">
            <div className="form-group">
              <label className="mont-font fw-600 font-xsss">Last Name*</label>
              <input
                type="text"
                className="form-control"
                value={formData.lastName}
                onChange={handleFormChange("lastName")}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 mb-3">
            <div className="form-group">
              <label className="mont-font fw-600 font-xsss">Gender*</label>
              <select
                name="gender"
                value={formData.gender}
                onChange={handleFormChange("gender")}
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>
          <div className="col-lg-6 mb-3">
            <div className="form-group">
              <label className="mont-font fw-600 font-xsss">Phone*</label>

              <PhoneInput
                country={"ma"}
                value={formData.phone}
                onChange={(phone) => setFormData({ ...formData, phone })}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 mb-3">
            <div className="form-group">
              <label className="mont-font fw-600 font-xsss">Country*</label>
              <CountryDropdown
                value={formData.country}
                onChange={(val) => setFormData({ ...formData, country: val })}
              />
            </div>
          </div>
          <div className="col-lg-6 mb-3">
            <div className="form-group">
              <label className="mont-font fw-600 font-xsss">State*</label>
              <RegionDropdown
                country={formData.country}
                value={formData.state}
                onChange={(val) => setFormData({ ...formData, state: val })}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 mb-3">
            <div className="form-group">
              <label className="mont-font fw-600 font-xsss">Password*</label>
              <input
                type="password"
                className="form-control"
                value={formData.password}
                onChange={handleFormChange("password")}
              />
            </div>
          </div>
          <div className="col-lg-6 mb-3">
            <div className="form-group">
              <label className="mont-font fw-600 font-xsss">
                Confirm Password*
              </label>
              <input
                type="password"
                className="form-control"
                value={formData.confirmPassword}
                onChange={handleFormChange("confirmPassword")}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default OnBoarding;
