import axios from "axios";

const API = "https://xyxm-adm5-et4s.n7.xano.io/api:1ehvYzJ9/web_registre";



export const registerUser = (phone, uid, requestId) => {

    return axios.post(`${API}`, {
        auth_phone: '+' + phone,
        uid: uid,
        request_id: parseInt(requestId)
    });

};