import React from "react";

const FirstStep = () => {
  const onChangeHandler = () => {};
  return (
    <div>
      <div className="row justify-content-center">
        <div className="firs-step">
          <a href="http://uitheme.net/index.html">
            <img src="/assets/img/logo.png" alt="logo" />
          </a>
        </div>
        <p className="font-lg">
          The next generation of networking app that helps you build trust and
          unlock new opportunities.
        </p>
        <p className="font-xs">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s
        </p>
      </div>
    </div>
  );
};

export default FirstStep;
