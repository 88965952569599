import { useState, useEffect } from "react";
import FirstStep from "./FirstStep";
import OnBoarding from "./OnBoarding";
import { toast } from "react-toastify";
import VerifyMobileNumber from "./VerifyMobileNumber";
import DownloadKarama from "./DownloadKarama";
import { registerUser } from "../services/verify.service";
import { useParams } from "react-router-dom";
import { verifyPinCode } from "../services/verifyPin.service";
import { onBoardingService } from "../services/onBoarding.service";

const InviteSteps = () => {
  const [tab, setTab] = useState(0);
  const { id } = useParams();
  const { request } = useParams();
  const [values, setValues] = useState(["", "", "", "", "", ""]);
  const [uploadedImage, setUploadedImage] = useState(null);

  const [formIsValid, setFormIsValid] = useState({
    isValid: false,
    message: "",
  });

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    gender: "male",
    phone: "",
    country: "",
    state: "",
    password: "",
    confirmPassword: "",
  });

  const handleIncreaseTab = async () => {
    if (tab === 1) {
      if (!formIsValid.isValid) {
        toast.error(formIsValid.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        setLoading(true);

        await registerUser(formData.phone, id, request)
          .then((res) => {
            if (res.data.data.status) {
              toast.success("SMS sent to your mobile number", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
              setTab(tab + 1);
            } else {
              toast.error(res.data.data.result, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }

            setLoading(false);
          })
          .catch((e) => {
            toast.error(e.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            setLoading(false);
          });
      }
    } else if (tab === 2) {
      setLoading(true);

      await verifyPinCode(formData.phone, values.join(""))
        .then(async (res) => {
          if (res.data.data.status) {
            if (uploadedImage && uploadedImage !== null) {
              onBoardingService(
                formData.phone,
                formData.password,
                res.data.data.result,
                formData.firstName,
                formData.lastName,
                formData.gender,
                formData.state,
                formData.country,
                "",
                uploadedImage
              )
                .then((res) => {
                  if (res.data.data.status) {
                    setTab(tab + 1);
                  } else {
                    toast.error(res.data.data.result, {
                      position: "top-center",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "dark",
                    });
                  }
                })
                .catch((e) => {
                  toast.error(e.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                  });
                });
            } else {
              onBoardingService(
                formData.phone,
                formData.password,
                res.data.data.result,
                formData.firstName,
                formData.lastName,
                formData.gender,
                formData.state,
                formData.country,
                ""
              )
                .then((res) => {
                  if (res.data.data.status) {
                    setTab(tab + 1);
                  } else {
                    toast.error(res.data.data.result, {
                      position: "top-center",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "dark",
                    });
                  }
                })
                .catch((e) => {
                  toast.error(e.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                  });
                });
            }
          } else {
            toast.error(res.data.data.result, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }

          setLoading(false);
        })
        .catch((e) => {
          toast.error(e.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          console.log(e.message);
          setLoading(false);
        });
    } else if (tab < 3) {
      setTab(tab + 1);
    }
  };

  const handleDecreaseTab = () => {
    if (tab !== 0) {
      setTab(tab - 1);
    }
  };

  useEffect(() => {
    if (
      formData.firstName.length > 2 &&
      formData.lastName.length > 2 &&
      formData.phone.length > 10 &&
      formData.country.length > 2 &&
      formData.state.length > 2
    ) {
      if (formData.password === formData.confirmPassword) {
        setFormIsValid({
          isValid: true,
          message: "",
        });
      } else {
        setFormIsValid({
          isValid: false,
          message: "Confirm password doesn't match password",
        });
      }
    } else {
      setFormIsValid({
        isValid: false,
        message: "Please fill in all the required fields",
      });
    }
  }, [formData]);

  const loadingCallBack = (state) => {
    setLoading(state);
  };

  return (
    <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left">
          <div className="middle-wrap">
            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
              <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                <a
                  href="http://uitheme.net/default-settings.html"
                  className="d-inline-block mt-2"
                >
                  <i className="fa-regular fa-circle-user"></i>
                </a>
                <h4 className="font-xs text-white fw-500 ms-4 mb-0 mt-2">
                  {tab === 0 && "1/4 Start Using Karama"}
                  {tab === 1 && "2/4 Personal Information"}
                  {tab === 2 && "3/4 Confirm Mobile Number"}
                  {tab === 3 && "4/4 Download Karama App"}
                </h4>
              </div>
              <div className="card-body p-lg-5 p-4 w-100 border-0">
                {tab === 0 && <FirstStep />}
                {tab === 1 && (
                  <OnBoarding
                    formData={formData}
                    setFormData={setFormData}
                    loadingCallBack={loadingCallBack}
                    uploadedImage={uploadedImage}
                    setUploadedImage={setUploadedImage}
                  />
                )}
                {tab === 2 && (
                  <VerifyMobileNumber
                    mobileNumber={formData.phone}
                    formData={formData}
                    loadingCallBack={loadingCallBack}
                    values={values}
                    setValues={setValues}
                  />
                )}
                {tab === 3 && <DownloadKarama />}

                <div
                  className="row d-sm-flex justify-content-between"
                  style={{ marginTop: "30px" }}
                >
                  <button
                    className={` ${
                      tab === 0 ? "bg-grey text-grey" : "bg-current text-white"
                    } text-center  font-xss p-2 w150 rounded-3 d-inline-block `}
                    onClick={() => {
                      handleDecreaseTab();
                    }}
                  >
                    Prev
                  </button>
                  {tab !== 3 && (
                    <button
                      className={`${
                        (tab === 1 && !formIsValid.isValid) || loading
                          ? "bg-grey text-grey"
                          : "bg-current text-white"
                      } text-center  font-xss p-2 w150 rounded-3 d-inline-block`}
                      onClick={() => {
                        if (!loading) {
                          handleIncreaseTab();
                        }
                      }}
                    >
                      {loading && (
                        <div
                          className="d-inline"
                          style={{ marginLeft: "-52px" }}
                        >
                          <div className="lds-spinner">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      )}

                      {!loading ? "Next" : ""}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteSteps;
