import React from "react";

const DownloadKarama = () => {
  return (
    <div>
      <div className="row justify-content-center">
        <p className="font-md">
          Download Karama App and wait for the request owner to accept your
          request.
        </p>
        <div
          className="d-flex justify-content-center"
          style={{ flexWrap: "wrap" }}
        >
          <a href="/#">
            <img
              className="w250"
              src="/assets/img/play-store.png"
              alt="play store"
            />
          </a>
          <a href="/#">
            <img
              className="w250"
              src="/assets/img/app-store.png"
              alt="play store"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default DownloadKarama;
