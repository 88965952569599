import axios from "axios";

const API = "https://xyxm-adm5-et4s.n7.xano.io/api:1ehvYzJ9/web_onboarding";



export const onBoardingService = (phone, password, token, firstName, lastName, gender, city, country, state, image) => {

    return axios.post(`${API}`,
        {
            "phone": '+' + phone,
            "password": password,
            "token": token,
            "first_name": firstName,
            "last_name": lastName,
            "gender": gender,
            "city": city,
            "country": country,
            "state": city,
            "image": image
        }
    );

};