import axios from "axios";

const API = "https://xyxm-adm5-et4s.n7.xano.io/api:BG09bi8f/pin_verification";



export const verifyPinCode = (phone, pin) => {

    return axios.post(`${API}`, {
        "phone": '+' + phone,
        "pin": pin
    });

};